<template>
  <div class="row">
    <header class="text-center">
      <h2>Certificates</h2>
      <div class="horizontalLine"></div>
    </header>

    <div class="container">
      <div class="row row-cols-1 row-cols-md-3 g-5">

        <div class="col">
          <div class="borderContainer h-100 d-flex flex-column p-4">
            <div class="d-flex flex-column justify-content-between h-100">
              <div>
                <h4>Courses</h4>
                <p>Codeacademy</p>
              </div>

              <div class="text-center mt-auto">
                <a
                  href="https://www.codecademy.com/profiles/AndreasRichter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button type="button" class="btnPrimary">
                    Open Certificates
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="borderContainer h-100 d-flex flex-column p-4">
            <div class="d-flex flex-column justify-content-between h-100">
              <div>
                <h4>Certified Frontend-Developer</h4>
                <p>W3Schools.com</p>
              </div>
              <div class="text-center mt-auto">
                <a
                  href="/certificates/certi_fe.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button type="button" class="btnPrimary">
                    Open Certificate
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="borderContainer h-100 d-flex flex-column p-4">
            <div class="d-flex flex-column justify-content-between h-100">
              <div>
                <h4>IT-Sicherheitsbeauftragter I</h4>
                <p>Bundesamt für Sicherheit in der Informationstechnik (BSI)</p>
              </div>
              <div class="text-center mt-auto">
                <a
                  href="/certificates/certi_isb.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button type="button" class="btnPrimary">
                    Open Certificate
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
